import * as React from "react";
import PageLayout from "../components/PageLayout";
import { StaticImage } from "gatsby-plugin-image";

// markup
const BFF2022 = () => {
  return (
    <PageLayout>
      <div className="center">
        <div className="content">
          <h1 className="title">Bentonville Film Festival 2022 - The Cinematic Center NFT</h1>
          <div className="image">
            <StaticImage src="../images/bff-2022-nft-min.jpg" alt="Bentonville Film Festival 2022 - The Cinematic Center NFT" layout="constrained" width={400} />
          </div>
          <div className="description">
            <p>
              Bentonville Film Festival (BFF) presents The Cinematic Center NFT, featuring digital art created by Sarah Richardson from Little Rock, AR. This is BFF's inaugural NFT collection.
              <br />
              <br />
              This NFT provides access to a future, exclusive virtual event with Geena Davis, along with a few other surprises over time. Additionally, for every 50 NFTs minted, BFF will award a Cinematic Membership to an NFT holder through a raffle that will be held after the close of the 2022 festival (you must be signed up to the newsletter to be notified: 
              <a href="https://bentonvillefilm.org/newsletter" target="_blank">
                https://bentonvillefilm.org/newsletter
              </a>
              ).
            </p>
          </div>
        </div>
      </div>
      <hashku-mint api-url="https://api.hashku.com/api" team-slug="bff" project-slug="2022" contract-address="0xb37c9EA5D300f84753A306432a4B47DE0016cAbf" contract-network="1" infura-id="3ed982906a2d473f8918f0730b2ea194" signup-only="no" winter-url="https://checkout.usewinter.com/?projectId=3190" mintNow="yes" />
    </PageLayout>
  );
};

export default BFF2022;
